import React from 'react'
import { useSiteMetadata } from '../components/SiteData'
import AppLayout from '../components/AppLayout'
import PageInfo from '../components/PageInfo'
import {
	ArticleBody,
	ArticleCopy,
	ArticleHeaderImg,
	ArticleImg,
	ArticleList,
	ArticleListItem,
	ArticlePage
} from '../components/common/Article'
import { graphql } from 'gatsby'
import AnchorLink from '../components/shared/AnchorLink'
import RouterLink from '../components/shared/RouterLink'
import UnsplashPhotoCredit from '../components/common/UnsplashPhotoCredit'
import { SchemaMarkupArticleAsSupplementalBlog } from '../components/common/SchemaMarkup'

export default function TimeManagementHabits(props) {
	let {data} = props;
	const {productName} = useSiteMetadata();

	const title = "10 Successful Time Management Habits to Launch Productivity";

	return (
		<AppLayout overlayNavWhenWide>
			<PageInfo
				title={"10 Successful Time Management Habits to Launch Productivity - " + productName}
				description={"Certain habits can boost your productivity. We discuss ten of these habits."}
			/>
			<SchemaMarkupArticleAsSupplementalBlog/>

			<ArticlePage>
				<ArticleHeaderImg
					alt="Time Management Habits"
					caption={<UnsplashPhotoCredit author="Kelly Sikkema" href="https://unsplash.com/@kellysikkema"/>}
					imgData={data.headerImg.childImageSharp.gatsbyImageData}
					articleTitle={title}
				/>

				<ArticleBody articleTitle={title}>
					<ArticleCopy>
						Do you sometimes find yourself overwhelmed by how fast time slips by? This
						can happen if you are fighting off distractions and have things to do.
						That's why we need to wisely manage our time so that we aren't wasting it.
						After all, it is a valuable resource. To that end, we present these 10 time
						management habits. Use them to help you stay on track and keep your
						productivity up.
					</ArticleCopy>

					<ArticleList gutters>
						<ArticleListItem title="Have quality sleep">
							First thing's first: how have you been sleeping? Is your sleep not
							restful or do you always feel like a zombie? If you answered "yes," then
							we recommend taking steps to improve the quality of your sleep. A lack
							of sleep or poor quality sleep can prevent your brain from operating in
							top condition. And if that is an issue, then the rest of these items may
							not be as important. Your mind is paramount to your ability to be
							productive. Correcting sleep issues is a bit out of the scope of this
							article. But a good starting point could be to have a conversation with
							a healthcare professional.
						</ArticleListItem>

						<ArticleListItem title="Focus on one task at a time">
							Let's go ahead and admit it. As human beings we are not great at
							multitasking. Sometimes we seem to be able to do more than one thing at
							a time. But when your tasks are time sensitive, you can't afford to
							sacrifice productivity. <AnchorLink href="https://www.forbes.com/sites/lisaquast/2017/02/06/want-to-be-more-productive-stop-multi-tasking">You should avoid multitasking.</AnchorLink> Even though it
							may seem like a way to optimize the usage of your time, it is not. Most
							likely you'll end up using more time, doing inferior work, or both.
						</ArticleListItem>

						<ArticleImg
							alt="Single Task"
							caption={<UnsplashPhotoCredit author="Photos by Lanty" href="https://unsplash.com/@photos_by_lanty"/>}
							imgData={data.signageOneImg.childImageSharp.gatsbyImageData}
							indented
						/>

						<ArticleListItem title="Maintain online discipline">
							Amazing technology that surrounds us has had a great impact on our
							lives. Computers, tablets, smartphones, and even smartwatches are
							everywhere. You probably use some of these tools to get work done. But
							the ever-present call of the internet can be a distraction. Social media
							sites keep us connected, and we all have other favorite time wasters.
							Yet when we are working, keeping on task is important. This is
							especially true when working from home as a
							{' '}
							<RouterLink to="/freelance-time-tracking/">freelancer or independent contractor</RouterLink>.
							It's ok to take a quick break here and there to keep your mind fresh.
							But you should be mindful. Avoid lettings these distractions damage your
							overall productivity. You can use a
							{' '}
							<RouterLink to="/timesheet-app/">timesheet app</RouterLink>
							{' '}
							to block out your time away from online distractions.
						</ArticleListItem>

						<ArticleListItem title="Make goals">
							If you don't have a goal to work toward, you might wind up in a
							seemingly endless march. You may find that your motivation suffers. But
							if you set some goals you could avoid that pitfall. Try setting
							long-term goals with short-term goals to indicate progress. With
							{' '}
							<RouterLink to="/time-management-goals/">clear goals</RouterLink>,
							you'll have a finish line in sight. You won't waste time going
							down the wrong path or end up finding yourself at a standstill.
						</ArticleListItem>

						<ArticleListItem title="Pick out one highest priority task to complete each day">
							To-do lists can be long and pull you in all sorts of directions. Narrow
							down and identify important tasks on your to-do list that should be
							completed. Once you've done that, pick one that is your highest priority
							for the day. Soon, over a period of a few days, you'll realize that
							you've knocked out plenty of these tasks.
						</ArticleListItem>

						<ArticleListItem title="Limit priorities">
							Having too many tasks can cause too much competition for our limited
							time. Have you worked in an environment where everything is "priority
							one" all the time? Importance is relative. In fact, ranking things by
							importance is meaningless if everything is at the top. This is
							counterproductive if you are trying to determine what tasks to perform.
							To keep this from happening, you should try to limit use of the
							"priority one" label. Save it for those items that are actually the most
							important.
						</ArticleListItem>

						<ArticleListItem title="Be consistent">
							Perhaps you've got some momentum going now. But if you let yourself lose
							track of your goals, you may find your productivity starting to slip
							away. Avoid this pitfall by being consistent with your work.
							Being consistent is one of the most important time management habits.
							Make a plan to put in an appropriate amount of time and effort on a
							regular basis according to your goals.
						</ArticleListItem>

						<ArticleListItem title="Put effort into being organized">
							Finding the right tool at the right time can prevent you from having to
							interrupt your work. Keeping notes and ideas organized can also save you
							a lot of wasted time and effort. If you are not organized when working
							on your tasks, you may later realize that you forgot something. Or you
							may realize that you could have done a better job if you had all the
							relevant resources available. Making an effort to have these notes and
							ideas organized will allow you to use them at the right time. Then you
							can avoid having to go back and rework things so much.
						</ArticleListItem>

						<ArticleImg
							alt="Organized"
							caption={<UnsplashPhotoCredit author="Edgar Chaparro" href="https://unsplash.com/@echaparro"/>}
							imgData={data.organizedImg.childImageSharp.gatsbyImageData}
							indented
						/>

						<ArticleListItem title="Delegate tasks">
							Are you familiar with the term "jack-of-all-trades"? The second part of
							that expression is "master of none". People are not experts on all
							topics. Talented people around you that are more specialized for the
							task at hand can be a good resource. Delegating to them will let you
							have more time to invest in the topics where you excel. You don't have
							all the time in the world, so to get many things done you'll need to
							rely on others that you trust. Otherwise you're limiting your
							productivity.
						</ArticleListItem>

						<ArticleListItem title="Don’t overbook your calendar">
							Did you ever have one too many meetings? Trying to fit too many things
							on the calendar doesn't allow enough time for each individual item. You
							will not give enough attention to each task. This can also cause stress
							as you rush from item to item. You may also find that you do not have
							enough time allocated to handle some items at all. This reduces
							productivity because you'll need to spend extra time catching up. Or
							you'll end up reworking things that you didn't have enough time for in
							the first place.
						</ArticleListItem>
					</ArticleList>

					<ArticleCopy>
						Now that we've listed these helpful time management habits, can you see any that you could
						put into practice? You may find that managing your time becomes easier, and
						you'll be able to make the most of your day.
						Browse our <RouterLink to="/time-management-101/">Time Management 101</RouterLink> resource page for more tips and strategies.
					</ArticleCopy>
				</ArticleBody>
			</ArticlePage>
		</AppLayout>
	);
}
export const query = graphql`{
  headerImg: file(relativePath: {eq: "images/articles/time-management-habits/kelly-sikkema--1_RZL8BGBM-unsplash.jpg"}) {
    childImageSharp { gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH) }
  }
  signageOneImg: file(relativePath: {eq: "images/articles/time-management-habits/photos-by-lanty-T-VS-7y_fAY-unsplash.jpg"}) {
    childImageSharp { gatsbyImageData(width: 439, placeholder: BLURRED, layout: CONSTRAINED) }
  }
  organizedImg: file(relativePath: {eq: "images/articles/time-management-habits/edgar-chaparro-r6mBXuHnxBk-unsplash.jpg"}) {
    childImageSharp { gatsbyImageData(width: 400, placeholder: BLURRED, layout: CONSTRAINED) }
  }
}
`;
